@media only screen and (max-width: 800px) {
    .Nav {
      position: absolute;
      align-items: center;
      object-position: center;
      right: 0;
      left: 20px;
      font-size: 100px;
      top: -10px;
    }
  }