body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  
  /* Main Settings  */
  --main-background-color: rgb(0, 0, 0);

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: #fff;
  --nav-link-hover-color: #f1439a;
  --nav-link-hover-background-color: none;
  --nav-background-color: #0e0e0e;
  --nav-height: 60px;
  --nav-logo-width: 125px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: black;
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(255, 255, 255);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #212029;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;


  /* Minting Button */
  --minting-button-color: linear-gradient(180deg,#f1439a 0%,#f1439a 100%);
  --minting-button-text-color: white;

}







