@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");
@import url('https://fonts.googleapis.com/css?family=Poiret+One|Source+Sans+Pro');

.App {
  text-align: center;
}



@media(max-width: 700px) {
  .animate-charcter
  {
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #d5d2df 0%,
      #ffffff 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 25px;
    text-align: center;
    justify-content: center;
  }
  
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  
  .container-con {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    margin-top: 2rem;
    padding: 1rem;
    max-width: 250px;
  }
}

@media(min-width: 700px) {
  .animate-charcter
  {
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #d5d2df 0%,
      #ffffff 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 55px;
    text-align: center;
    justify-content: center;
  }
  
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  
  .container-con {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    margin-top: 3rem;
    padding: 1rem;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  color: var(--main-text-color);
  position: relative;
  background: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.calendar {
   display: block;
   height: 200px;
   margin-left: auto;
   margin-right: auto;
   align-items: center;

}


nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 1000;
}
#welcome, #mint, #milestones, #faq{
  scroll-margin-top: 50px;
}
.nav-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 98vw;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
  font-weight: 600;
  padding: 20px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 1001;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.Minting {

  height: 120px;
  width: auto;
  position: relative;
  top: 300px;

  justify-content: center;
  align-items: center;

}

.Mintbutton {

  height: 120px;
  width: auto;



}



.mobile-nav {
  position: fixed;
  min-height: 120vh;
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 1002;
  width: 100%;
  transition: left 0.3s ease;
  top: 0;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 20px;
  background: rgb(56, 56, 56);
  padding: 7px;
  border-radius: 20px;

}

.social-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
    height: 50px !important;
  }
  .col-xl-6{
    width: 100%!important;
  }
  nav {
    height: 100px;
  }
  .row{
    flex-direction: column;
    margin-top: 30px!important;
  }
  .col-lg-8{
    max-width: 95%!important;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}



.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}


body {
  font-family: "Inter", sans-serif !important;

}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

marquee {
  display: block;
}

.navbar-brand>img {
  width: 100px;

}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.comingsoon {
  text-align: center;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #1a191b;
}

.makeStyles-root-2 {
  display: none !important;
}

#mint {
 background-size: contain;
}

@media(max-width: 600px) {
  #mint {

    background-size: cover;
  }
}

.border-warning {
  border-color: #f1439a !important;
}

.text-warning {
  color: #f1439a !important;
}

.bg-warning {
  background-color: #f1439a !important;
}

.bg-dark {
  background-color: #0f1111 !important;
}

.py-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.main-title {
  font-size: 60px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  padding-left: 7px;
  font-family: 'nuku', sans-serif;
}

.main-title.center {
  text-align: center;
  padding-left: 0px;
}

.main-title.center:before {
  content: "";
  position: absolute;
  left: auto;
}

.main-title.center.whitedot:before {
  background-color: #f1439a;
}

#team h2 {
  color: white;
}

.main-title:before {
  content: "";
  position: absolute;
  width: 56px;
  height: 56px;
  background-color: #f1439a;
  border-radius: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.main-title.white {
  color: white;
}

/* ========================== Header CSS Start ========================== */

header .navbar {
  background-color: #0e0e0e;
  padding-bottom: 5px;
  padding-top: 5px;
}

header .navbar-dark .navbar-nav .nav-item {
  padding-left: 8px;
  padding-right: 8px;
}

header .navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 700;
  padding: 5px 12px;
}

header .nav-link:focus,
header .navbar-dark .navbar-nav .nav-link:hover {
  color: #1f1408;
  background-color: #f1439a;
  border-radius: 3px;
  box-shadow: 0 2px 0 #f1439a;
}

header .navbar-dark .navbar-nav .active>.nav-link,
header .navbar-dark .navbar-nav .nav-link.active,
header .navbar-dark .navbar-nav .nav-link.show,
header .navbar-dark .navbar-nav .show>.nav-link {
  color: #1f1408;
  background-color: #f1439a;
  border-radius: 3px;
  box-shadow: 0 2px 0 #f1439a;
}

.header {
  background: url(./web.jpg) no-repeat center;
  background-size: contain;
  height: 1000px;
}

header .navbar-dark .navbar-nav.social-media .nav-link {
  background-color: #5f5f5f;
  color: #f1439a;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
}

header .social-media .nav-link:focus,
header .navbar-dark .navbar-nav.social-media .nav-link:hover {
  background-color: #f1439a !important;
  border-radius: 100% !important;
  box-shadow: none !important;
  color: #fff !important;
}

header .navbar-dark .navbar-nav.social-media .nav-item {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

/* ========================== Header CSS End ========================== */

/* ========================== Timer CSS Start ========================== */

.timer-main {
  border: 1px solid #241c31 !important;
  padding: 20px !important;
}

.timer-main .flipdown {
  margin: auto;
}

.timer-main h2 {
  color: #1a191b;
  font-family: "Montserrat", sans-serif;
}

.timer-main p {
  font-weight: 300;
  color: #4a4a4a;
  font-size: 26px;
  line-height: 34px;
  font-family: "Montserrat", sans-serif;
}

.flipdown.flipdown__theme-dark .rotor,
.flipdown.flipdown__theme-dark .rotor-top,
.flipdown.flipdown__theme-dark .rotor-leaf-front {
  background-color: #f1439a;
}

.flipdown.flipdown__theme-dark .rotor-bottom,
.flipdown.flipdown__theme-dark .rotor-leaf-rear {
  color: #fff;
  background-color: #f1439a;
}

.flipdown.flipdown__theme-dark .rotor:after {
  border-top: solid 1px #f1439a;
}

.flipdown.flipdown__theme-dark .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
.flipdown.flipdown__theme-dark .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
  background-color: #f1439a;
  left: 113px;
}

.flipdown .rotor-group {
  padding-right: 26px;
}

.flipdown.flipdown__theme-dark .rotor-group-heading:before {
  color: #1a191b;
  font-size: 15px;
}

/* ========================== Timer CSS End ========================== */
/* ========================== Mint Now CSS Start ========================== */

.mint-now-box {

  border-radius: 5px;
  box-shadow: 0 2px 0 #efe7cb;
}

.mint-header {
  background-color: #ffca28;
  padding: 12px 30px;
  border-radius: 5px 5px 0 0;
  position: relative;
}

.mint-header-text {
  width: 75%;
}

.mint-box-logo {
  position: absolute;
  right: 0;
  top: 0;
}

.mint-box-logo img {
  border-radius: 0 5px 0 0;
  max-width: 100%;
}

.mint-box-title {
  font-size: 28px;
}

.mint-box-para {
  font-size: 18px;
  color: #1a191b;
  line-height: 24px;
}

.mint-box-body {
  padding: 45px 30px 20px 30px;
}

.price-box {
  background: linear-gradient(120deg, #f8e596, #ffffff);
  border-radius: 5px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
}
.row{
  display: flex;
  max-width: 80vw;
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.col-xl-6{
  width: 50%;
}
.align-items-center{
  align-items: center;
}
.price-box img {
  margin-top: -20px;
}

.price-text-box {
  padding: 10px;
}

.price-text {
  font-size: 16px;
  color: #1a191b;
  font-weight: 700;
}

.price-tag {
  font-size: 20px;
  color: #1a191b;
  font-weight: 700;
  background-color: #ffca28;
  border-radius: 3px;
  padding: 6px;
}

.mint-now-box .input-group-append .btn {
  background-color: #fff;
  font-size: 17px;
  color: #7a7a7a;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
}

.mint-now-box .form-control {
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #1a191b;
  border: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
}

.total-box {
  background-color: #3a353f;
  border-radius: 5px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 18px;
  color: #fff;
  text-align: center;
  display: block;
  padding: 10px;
  text-transform: uppercase;
  margin-top: 15px;
}

.mint-btn {
  border: 2px solid #fff;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 40px;
}

@media screen and (max-width: 1199px) {
  .mint-box-body {
    padding: 57px 30px 20px 30px;
  }
}

@media screen and (max-width: 767px) {
  .mint-box-body {
    padding: 45px 30px 20px 30px;
  }
}

@media screen and (max-width: 550px) {
  .mint-box-logo {
    width: 100px;
  }
}

/* ========================== Mint Now CSS End ========================== */

/* ========================== Sold Out CSS Start ========================== */

.sold-box {

  border: 5px solid #efe7cb;
}

/* ========================== Sold Out CSS End ========================== */

/* ========================== The Numbers CSS Start ========================== */

/*.numbers-main {
      background: url(../../assets/img/numbers-left-bg.jpg) top left no-repeat, url(../../assets/img/numbers-top-bg.jpg) top left 40px no-repeat, url(../../assets/img/numbers-right-bg.jpg) top right no-repeat, url(../../assets/img/numbers-bottom-bg.jpg) bottom left 40px no-repeat, #ffca28 !important;
      padding: 34px 60px 50px 50px;
    }*/
.numbers-main {
  background: #f1439a;
  padding: 21px 60px 45px 50px;
  margin-top: 50px;

}

.number-list {
  margin-top: 14px;
}

.number-list li {
  font-size: 18px;
  color: #000;
  margin-bottom: 4px;
}

.number-list li:last-child {
  margin-bottom: 0px;
}

.number-list li img {
  vertical-align: middle;
}

/* ========================== The Numbers CSS End ========================== */

/* ========================== Welcome Part CSS Start ========================== */




.welcome-text {
  font-family: 'nuku', sans-serif;
  font-size: 40px;
  margin-bottom: -5px;
  display: block;
  margin-top: 30px;
}

.welcome-para {
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
}


.img-fluid{
  max-width: 100%;
  height: auto;
}
#story img {
  margin-top: 40px;
}
.col-lg-4{
  max-width: 33%;
}
.col-lg-8{
  max-width: 66%;
}
.row{
  align-items: center;
  margin: auto;
}
.gap50{
  gap: 50px;
}
/* ========================== Welcome Part CSS End ========================== */

/* ========================== Slider Part CSS Start ========================== */
.marquee-wrapper h2 {
  color: white;
}

.slider-part {
  padding: 30px 0 !important;
  background-color: #09070c !important;
}

.slider-part h5 {
  color: white;
}

@media(max-width: 600px) {
  .slider-part h5 {
    display: none;
  }

}

.marquee-wrapper {
  text-align: center;
}

.marquee-wrapper .container {
  overflow: hidden;
  max-width: 100%;
}

.marquee-inner span {
  float: left;
  width: 50%;
}

.marquee-wrapper .marquee-block {
  --total-marquee-items: 11;
  height: 204px;
  width: calc(177.5px * (var(--total-marquee-items)));
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 10px auto;
}

.marquee-inner {
  display: -webkit-block;
  display: -moz-block;
  display: -ms-block;
  display: block;
  width: 200%;
  position: absolute;
}

.marquee-inner.to-left {
  -webkit-animation: marqueeLeft 60s linear infinite;
  -moz-animation: marqueeLeft 60s linear infinite;
  -o-animation: marqueeLeft 60s linear infinite;
  -ms-animation: marqueeLeft 60s linear infinite;
  animation: marqueeLeft 60s linear infinite;
}

.marquee-inner.to-right {
  -webkit-animation: marqueeRight 60s linear infinite;
  -moz-animation: marqueeRight 60s linear infinite;
  -o-animation: marqueeRight 60s linear infinite;
  -ms-animation: marqueeRight 60s linear infinite;
  animation: marqueeRight 60s linear infinite;
}

#aboutme p {
  font-size: 22px;
  color: white;
  padding: 40px;
  text-align: justify;
  max-width: 90vw;
  margin: auto;
}

.marquee-item {
  width: 180px;
  height: auto;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-block;
  margin: 0 5px;
  /* float: left; */
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  border: 2px solid #343337;
  padding: 8px;
}

@-moz-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-webkit-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-o-keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@keyframes marqueeLeft {
  0% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }
}

@-moz-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@-webkit-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@-o-keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

@keyframes marqueeRight {
  0% {
    -webkit-left: -100%;
    -moz-left: -100%;
    -o-left: -100%;
    -ms-left: -100%;
    left: -100%;
  }

  100% {
    -webkit-left: 0;
    -moz-left: 0;
    -o-left: 0;
    -ms-left: 0;
    left: 0;
  }
}

/* ========================== Slider Part CSS End ========================== */

/* ========================== Rarity Part CSS Start ========================== */
.rarity-part {
  background: #614ae5cb;
}

.rarity-para {
  color: #fff;
  -webkit-text-stroke: 1px black;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
}

.rarity-info {
  border-radius: 20px;
  box-shadow: 0 4px 0 #f1439a;
  text-align: center;
}

.rarity-info ul li {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 700;
  border-right: 1px solid #dddddd;
  padding: 0 18px;
  margin: 0;
}

.rarity-info ul li:last-child {
  border-right: none;
}

.rarity-info ul li span {
  font-size: 26px;
}

/* ========================== Rarity Part CSS End ========================== */

/* ========================== Milestones CSS Start ========================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.milestones-part ul li {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.milestones-part h2 {}

.title-roadmap {
  color: #f1439a;
  font-size: 80px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  text-align: center;
}

.milestones-title {
  color: #f5c90c;
  font-weight: 100;
  font-size: 60px;
  text-transform: uppercase;
  font-family: 'nuku', sans-serif;
}

.right.two::before {
  border: medium solid #fe8700;
  border-width: 24px 24px 24px 0;
  border-color: transparent #fe8700 transparent transparent;
}

.right.two::after {

  background-size: cover;
  border-radius: 100px;
}

.right.two .time-content {
  border: 4px solid #fe8700;
}

.right.two .milestones-title {
  color: #fe8700;
}

.right.three::before {
  border: medium solid #eb314e;
  border-width: 24px 24px 24px 0;
  border-color: transparent #eb314e transparent transparent;
}

.right.three::after {

  background-size: cover;
  border-radius: 100px;
}

.right.three .time-content {
  border: 4px solid #eb314e;
}

.right.three .milestones-title {
  color: #eb314e;
}

.right.four::before {
  border: medium solid #00abff;
  border-width: 24px 24px 24px 0;
  border-color: transparent #00abff transparent transparent;
}

.right.four::after {

  background-size: cover;
  border-radius: 100px;
}

.right.four .time-content {
  border: 4px solid #00abff;
}

.right.four .milestones-title {
  color: #00abff;
}

.right.five::before {
  border: medium solid #c45bff;
  border-width: 24px 24px 24px 0;
  border-color: transparent #c45bff transparent transparent;
}

.right.five::after {

  background-size: cover;
  border-radius: 100px;
}

.right.five .time-content {
  border: 4px solid #c45bff;
}

.right.five .milestones-title {
  color: #c45bff;
}

.left.two::before {
  border: medium solid #b7ff46;
  border-width: 24px 0 24px 24px;
  border-color: transparent transparent transparent #b7ff46;
}

.left.two::after {

  background-size: cover;
  border-radius: 100px;
}

.left.two .time-content {
  border: 4px solid #b7ff46;
}

.left.two .milestones-title {
  color: #b7ff46;
}

.left.three::before {
  border: medium solid #ffb6d1;
  border-width: 24px 0 24px 24px;
  border-color: transparent transparent transparent #ffb6d1;
}

.left.three::after {

  background-size: cover;
  border-radius: 100px;
}

.left.three .time-content {
  border: 4px solid #ffb6d1;
}

.left.three .milestones-title {
  color: #ffb6d1;
}

.left.four::before {
  border: medium solid #00fff9;
  border-width: 24px 0 24px 24px;
  border-color: transparent transparent transparent #00fff9;
}

.left.four::after {

  background-size: cover;
  border-radius: 100px;
}

.left.four .time-content {
  border: 4px solid #00fff9;
}

.left.four .milestones-title {
  color: #00fff9;
}

.time-content p {
  font-size: 18px;
  color: #fff;
  line-height: 24px;
  border-top: 1px solid #725d48;
  margin: 0;
  padding-top: 10px;
}

.timeline {
  position: relative;
  margin-top: 115px;
  padding: 60px 0 110px;
  max-width: 80vw;
  margin: auto;
}
.centerjust{
  justify-content: center;
  gap: 30px;
}
.maxh{
  height: 500px;
}
.time-container {
  padding: 0px 110px 0px 20px;
  position: relative;
  background-color: inherit;
  width: 50%;
  z-index: 1;
}

.left {
  left: 0px;
}

.left::after {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
}

.time-content {
  padding: 10px 20px;
  background-color: #341f0a;
  position: relative;
  border-radius: 5px;
  border: 4px solid #f5c90c;
}

.right {
  left: 50%;
  padding: 0px 20px 0px 110px;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 160px;

  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -80px;
}

.time-container::after {
  content: "";
  position: absolute;
  width: 86px;
  height: 90px;
  right: -25px;

  background-size: cover;
  border-radius: 100px;
  top: 15px;
  z-index: 1;
}

.right::after {
  left: -25px;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 86px;
  border: medium solid #fe8700;
  border-width: 24px 24px 24px 0;
  border-color: transparent #fe8700 transparent transparent;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 86px;
  border: medium solid #f50cce;
  border-width: 24px 0 24px 24px;
  border-color: transparent transparent transparent #f5c90c;
}

@media screen and (max-width: 767px) {
  .timeline::after {
    left: 31px;
  }
}

@media screen and (max-width: 767px) {
  .time-container {
    width: 100%;
    padding-left: 117px;
    padding-right: 0px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .time-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
}

@media screen and (max-width: 767px) {

  .left::after,
  .right::after {
    left: 18px;
  }
}

@media screen and (max-width: 767px) {
  .right {
    left: 0%;
  }
}

/* ========================== Milestones CSS End ========================== */
#faq {
  margin-top: 100px;
}

/* ========================== Feature CSS Start ========================== */


.feature-bg {
 
  position: relative;
}

.feature-bg:before {
  content: "";
  position: absolute;
 
  height: 144px;
  width: 100%;
  top: -144px;
}

.feature-para {
  font-size: 22px;
  color: #cfcfcf;
  text-align: center;
}

.feature-box {
  border: 1px solid #505450 !important;
  padding: 30px !important;
  height: 100%;
}

.feature-box-title {
  color: #ebebeb;
  font-size: 24px;
  font-weight: 700;
}

.feature-text {
  color: #cfcfcf;
  font-size: 15px;
  line-height: 24px;
}

/* ========================== Feature CSS End ========================== */

/* ========================== Team CSS Start ========================== */

.team-title {
  font-size: 30px;
  color: #ffffff;
}

.team-title span {
  font-size: 20px;
}

.teamimg {
  width: 350px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 5px solid white;
}

/* ========================== Team CSS End ========================== */

/* ========================== FAQ CSS Start ========================== */

#accordion .card {
  border-radius: 0;
  border: 1px solid #505450;
  background: transparent;
  margin: 10px 0;
}

#accordion .card-header {
  padding: 30px 24px;
}

#accordion .card-header a {
  font-size: 24px;
  font-weight: 300;
  color: #cfcfcf;
  width: 100%;
  line-height: 1.2rem;
  position: relative;
  padding-right: 30px;
}

#accordion .card-header a:hover {
  text-decoration: none;
}

#accordion .card-header a i {
  position: absolute;
  right: 0;
}

#accordion .highlight a {
  color: #f1439a;
}

#accordion .card-body {
  padding-top: 0;
}

#accordion .card-body .card-text {
  color: #cfcfcf;
  font-size: 18px;
}

/* ========================== FAQ CSS End ========================== */

/* ========================== Footer CSS Start ========================== */

.footer-part {
  font-family: 'Poiret One';
  font-size: larger;
  font-weight: bolder;
  background-size: 100%;
  padding-top: 70px;
  position: relative;
  line-height: 30px;
}
.list-inline{
  display: flex;
  gap: 20px;
  justify-content: center;
}
.ftr-link a {
  background-color: #343434;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 6px 12px;
}

.ftr-link a:hover {
  background-color: #f1439a;
  color: #fff;
}


.comingsoon h2,
h3 {

  color: black !important;

}

.comingsoon {
  background-color: #f1439a;
  padding: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

/* ========================== Footer CSS End ========================== */

/* ========================== Responsive CSS Start ========================== */

@media (max-width: 600px) {
  .comingsoon {
    width: 80%;
  }
}

@media (min-width: 1921px) and (max-width: 2400px) {
  .header {
    height: 1000px;
  }
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .header {
    height: 690px;
    margin-top: 50px;
  }
}

@media (min-width: 1210px) and (max-width: 1599px) {
  .header {
    height: 350px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1199px) {
  .header {
    height: 520px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .numbers-main {

    padding: 20px;
    border: 5px solid #efe7cb;
  }
}

@media screen and (max-width: 1199px) {
  .rarity-info ul li {
    font-size: 17px;
    padding: 0 13px;
  }
}

@media screen and (max-width: 1199px) {
  header .navbar-dark .navbar-nav .nav-link {
    font-size: 14px;
  }

  header .navbar-dark .navbar-nav .nav-item {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media screen and (max-width: 991px) {
  .header {
    height: 430px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-toggler {
    padding: 3px 10px;
    background-color: #f1439a;
  }
}

@media screen and (max-width: 991px) {
  .social-media {
    flex-direction: row;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  header .navbar-collapse {
    margin-top: 10px;
  }
}

@media screen and (max-width: 991px) {
  .rarity-info ul li {
    display: block;
    font-size: 17px;
    border-right: none;
    border-bottom: 1px solid #dddddd;
    padding: 6px 0px;
  }
}

@media screen and (max-width: 991px) {
  .rarity-info {
    padding: 0 15px !important;
  }
}

@media screen and (max-width: 991px) {
  .rarity-para {
    font-size: 20px;
    line-height: 33px;
  }
}

@media screen and (max-width: 991px) {
  .milestones-title {
    font-size: 22px;
  }
}

@media screen and (max-width: 991px) {
  .time-content p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 991px) {
  .feature-para {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  #accordion .card-header a {
    font-size: 22px;
    line-height: 28px;
  }
}

@media screen and (max-width: 991px) {
  .footer-part:before {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 330px;
  }
}

@media screen and (max-width: 767px) {
  .flipdown .rotor-group {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .milestones-part {

    background-size: cover;
  }

}

@media screen and (max-width: 767px) {
  .timeline {
    margin-top: 36px;
  }
}

@media screen and (max-width: 767px) {
  .rarity-para {
    background-color: rgba(255, 255, 255, 0);
    padding: 6px;
  }
}

@media screen and (max-width: 575px) {
  .timer-main p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 575px) {
  .main-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 575px) {
  .welcome-text {
    font-size: 28px;
  }
}

@media screen and (max-width: 575px) {
  .main-title:before {
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 575px) {
  .welcome-part {
    background: none;
    background-color: #241c31;
  }
}

@media screen and (max-width: 575px) {
  .rarity-para {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 575px) {
  .feature-para {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 550px) {

  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):before,
  .flipdown .rotor-group:nth-child(n + 2):nth-child(-n + 3):after {
    left: 69px !important;
  }
}

.jumptarget::before {
  content: "";
  display: block;
  height: 0px;
  /* fixed header height*/
  margin: -50px 0 0;
  /* negative fixed header height */
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1 !important;
  transform: translateZ(0) !important;
}

.fade {
  position: relative;
  width: 100%;
  min-height: 60vh;
  top: -25px;
  background-image: linear-gradient(0deg, transparent, black 75%);
  z-index: 1;
}

.star-wars {
  display: flex;
  justify-content: center;
  position: relative;
  height: 800px;
  color: #feda4a;
  font-family: 'Pathway Gothic One', sans-serif;
  font-size: 500%;
  font-weight: 600;
  letter-spacing: 6px;
  line-height: 150%;
  perspective: 400px;
  text-align: justify;
  overflow: hidden;
}

.crawl {
  position: relative;
  top: 99999px;
  transform-origin: 50% 100%;
  animation: crawl 60s linear;
}

.crawl>.title {
  font-size: 90%;
  text-align: center;
}

.crawl>.title h1 {
  margin: 0 0 100px;
  text-transform: uppercase;
}

@keyframes crawl {
  0% {
    top: -100px;
    transform: rotateX(20deg) translateZ(0);
  }

  100% {
    top: -6000px;
    transform: rotateX(25deg) translateZ(-2500px);
  }
}

.disclaimer .container h3 {
  color: white !important;
  text-align: justify;
  font-size: 14px;
  padding: 50px;
}

footer p {
  color: white;
}

#howto p {
  color: white;
  text-align: justify;
}

#howto ol li {
  color: white;
  font-size: 18px;
  text-align: justify;
}

#howto .container {
  padding: 85px;
}
.MuiTypography-root{
  color: white;
}
.MuiSvgIcon-root{
  color: white;
}
.faq-item {
  padding: 10px 40px !important;
  background-color: transparent  !important;
  text-align: left !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
  position: relative !important;
  margin-bottom: 5vh !important;
  box-shadow: 15px 15px 0 #f1439a !important;
  max-width: 90vw;
  margin: auto;
  border: 1px solid #f1439a;
}

.MuiAccordionDetails-root {
  color: white !important;
}
.flexcharities {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
}
.charities h1{
  color: #f1439a!important;
  text-align: center;
}
.charity h2{
  font-size: 22px;
  color: #f1439a!important;
}
.charity{
  border: 1px solid #f1439a;
  padding: 10px;
  border-radius: 20px;
}
@media(max-width: 600px){
  .flexcharities{
    flex-direction: column;
  }
  .title-roadmap{
    font-size: 55px;
  }
}
.comicwrap{
  text-align: center;
}
.comicbook{
    text-decoration: none;
    color: #f1439a;
    font-weight: 600;
    font-size: 30px;
}
.prev20{
  display: flex;
  justify-content: center;
  width: 70vw;
  margin: auto;
  align-items: center;
  gap: 50px;
}
.textleft{
  width: 70%;
  color: white;
  font-size: 22px;
}
@media(max-width: 600px){
  .prev20{
    flex-direction: column;
    width: 90vw;
    gap: 30px;
  }
  .textleft{
    width: 100%;
  }
  .rightpic{
    margin-bottom: 20px;
  }
  .img-fluid{
    max-width: 300px;
    width: 300px;
    display: block;
    margin: auto;
  }
  .col-lg-4{
    max-width: 100%;
  }
  .timeline::after{
    display: none;
  }
  .time-container::before{
    display: none;
  }
  .time-container::after{
    display: none;
  }
  .time-container{
    padding-left: 0px;
  }
  .faq-item{
    max-width: 80vw;
  }
  .list-inline{
    flex-direction: column;
  }
}
.pinktext{
  color: #f1439a;
}

